@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;800&display=swap");

body {
  font-family: "Rubik", sans-serif;
  background-color: "#edf6f9";
  overflow-y: scroll; /* Add the ability to scroll */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox*/
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  animation: scrollRightToLeft 20s linear infinite;
  white-space: nowrap; /* Prevent card stacking */
  width: max-content;
  overflow: hidden;
  .card {
    flex: 0 0 auto;
  }
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-100% + 100px)
    ); /* Adjust 100px to your card width */
  }
}

.testimonial-card {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.testimonial-card::-webkit-scrollbar {
  display: none;
}

.logo-slider {
  overflow: hidden;
}

.logo-slide-track {
  display: flex;
  animation: none;
}

.slide {
  flex: 0 0 auto;
  width: 200px;
  /* height: 300px; */
  padding: 20px;
}

.logo-slider .slide img {
  max-width: 140px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
